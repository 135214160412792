.rc-dyn-tabs-tablist {
  background-color: transparent;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 0px 0px 1px 0px;
}
.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab {
  color: rgba(0, 0, 0, 0.63);
}
.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab > .rc-dyn-tabs-title {
  min-width: 160px;
  min-height: 48px;
  transform: translate3d(0, 0, 0);
  border-color: transparent;
  border-style: solid;
  border-width: 1px 1px 3px 1px;
  border-radius: 1px;
  background-color: #fff;
  opacity: 1;
}
.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab > .rc-dyn-tabs-title:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}
.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab.rc-dyn-tabs-selected > .rc-dyn-tabs-title {
  color: black;
  background-color: transparent;
  border-color: #3f51b5;
  border-style: solid;
  border-width: 0px 0px 3px 0px;
  opacity: 1;
}
.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab > .rc-dyn-tabs-title:hover {
  color: rgba(0, 0, 0, 0.63);
  background: #e6e6e6;
  transition: background 0.8s;
}
.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab > .rc-dyn-tabs-title:active:hover {
  color: rgba(0, 0, 0, 0.63);
  background-color: #d4d4d4;
}
.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab > .rc-dyn-tabs-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.rc-dyn-tabs-tablist > .rc-dyn-tabs-tab > .rc-dyn-tabs-close {
  top: 50%;
  transform: translateY(-50%);
  line-height: 0px;
  right: 10px;
}

.rc-dyn-tabs-tablist.rc-dyn-tabs-rtl > .rc-dyn-tabs-tab > .rc-dyn-tabs-close {
  right: auto;
  left: 10px;
}

.rc-dyn-tabs-tablist.rc-dyn-tabs-vertical {
  border-width: 0px 1px 0px 0px;
}
.rc-dyn-tabs-tablist.rc-dyn-tabs-vertical > .rc-dyn-tabs-tab > .rc-dyn-tabs-title {
  border-width: 0px 1px 0px 0px;
}
.rc-dyn-tabs-tablist.rc-dyn-tabs-vertical > .rc-dyn-tabs-tab.rc-dyn-tabs-selected > .rc-dyn-tabs-title {
  border-width: 0px 3px 0px 0px;
}

.rc-dyn-tabs-tablist.rc-dyn-tabs-vertical.rc-dyn-tabs-rtl {
  border-width: 0px 0px 0px 1px;
}
.rc-dyn-tabs-tablist.rc-dyn-tabs-vertical.rc-dyn-tabs-rtl > .rc-dyn-tabs-tab > .rc-dyn-tabs-title {
  border-width: 0px 0px 0px 1px;
}
.rc-dyn-tabs-tablist.rc-dyn-tabs-vertical.rc-dyn-tabs-rtl > .rc-dyn-tabs-tab.rc-dyn-tabs-selected > .rc-dyn-tabs-title {
  border-width: 0px 0px 0px 3px;
}
